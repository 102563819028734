import React, { useEffect } from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  // Prevent body scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    // Cleanup
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        zIndex: 1000,
        overflow: 'hidden'
      }}
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div 
        className="bg-white rounded-[45px] p-6 max-w-2xl w-full relative"
        style={{
          backgroundColor: 'white',
          borderRadius: '45px',
          padding: '2rem',
          maxWidth: '42rem',
          width: '100%',
          position: 'relative',
          border: '1px solid #191A23',
          boxShadow: '0px 5px 0px #191A23',
          maxHeight: '90vh',
          overflowY: 'auto',
          msOverflowStyle: 'none',  /* IE and Edge */
          scrollbarWidth: 'thin',   /* Firefox */
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-6 text-2xl"
          style={{
            position: 'sticky',
            color: 'red',
            top: '0.1rem',
            right: '0.1rem',
            fontSize: '1.5rem',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            float: 'right',
            zIndex: 1001
          }}
        >
          ×
        </button>
        <div style={{ clear: 'both' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;