import React, { useState } from 'react';
import { Headings } from '../components';
import { data } from '../constants';
import { Contact } from '../container';
import {images} from '../constants';
import Modal from './Modal';  // Import the Modal component

const EventsPage = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const openModal = (event) => {
    setSelectedEvent(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  return (
    <>
      <div id="services" className="d-block pt-md-4">
        <div className='event-h'>
          <div className='container'>
            <Headings title="Events" text="Join our upcoming event series to stay at the forefront of trends and innovations. These events offer valuable chances to deepen your expertise, enhance your skills, and connect with others in your field." />
          </div>
        </div>
      </div>

      <div className='container'> 
        <div id="services" className="d-block pt-md-4">
          <div className="row">
            {data.EventsData.map((event, index) => (
              <div className="col-12" key={index}>
                <div className={`row ${event.itemclass}`}>
                  <div className="col-md-6 start">
                    <img src={event.imgURL} alt={event.titleone} className="img-fluid" />
                  </div>
                  <div className="col-md-6">
                    <div>  
                      <span>{event.titleone}</span>
                      <p className='p2'>{event.details}</p>
                      <span className="col-md-2 col-12 fs-4 socials">
                        <button 
                          type="button" 
                          className="btn-positivus2 w-200 eventbutton"
                          onClick={() => openModal(event)}
                        >
                          Details
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal isOpen={!!selectedEvent} onClose={closeModal}>
        {selectedEvent && (
          <div className="event-modal-content">
            <h3 style={{ color: '#36A9E1', marginBottom: '1rem' }}>{selectedEvent.titleone}</h3>
            <img 
              src={selectedEvent.imgURL} 
              alt={selectedEvent.titleone} 
              style={{ 
                width: '70%', 
                height: 'auto', 
                borderRadius: '20px',
                marginBottom: '1rem'
              }} 
            />
            <h4 style={{ color: '#36A9E1', marginBottom: '1rem' }}>Overview</h4>
            <p style={{ fontSize: '1rem', lineHeight: '1.5', textAlign: 'justify' }}>The “Students And Graduates In Tech” is an annual tech conference for students and graduates of Universities in the United Kingdom, designed to bring together students and recent graduates, industry professionals, and innovators in the tech field. Our aim is to foster collaboration, inspire creativity, and provide networking opportunities and teach students how to transition and navigate through tech roles in the industry. The event will feature:
              <ul style={{ 
        listStyleType: 'disc',
        marginLeft: '0.5rem',
        marginBottom: '1rem',
        fontSize: '1.1rem',
        lineHeight: '1.6',
        textAlign: 'left',
      }}>
                <li>Keynote speakers from leading tech companies</li>
                <li>Workshops on the latest technologies and trends</li>
                <li>Panel sessions with industry experts</li>
                <li>Networking sessions to connect students with industry leaders</li>
                <li>⁠Exhibition of innovative tech products by individuals and companies</li>
              </ul>
            </p>
            {selectedEvent.titletwo && (
              <p style={{ marginTop: '1rem', fontWeight: 'bold' }}>{selectedEvent.titletwo}</p>
            )}
            {selectedEvent.link && (
              <a 
                href="https://www.eventbrite.co.uk/e/1083345919809?aff=oddtdtcreator"
                className="btn-positivus2"
                style={{ 
                  display: 'inline-block',
                  marginTop: '1rem',
                  textDecoration: 'none'
                }}
              >
                Reserve a spot
              </a>
            )}<br />
            <br />
            <h4 style={{ color: '#36A9E1', marginBottom: '1rem' }}>Conference Objectives</h4>
            <img 
              src={images.events012} 
              alt={selectedEvent.titleone} 
              style={{ 
                width: '70%', 
                height: 'auto', 
                borderRadius: '20px',
                marginBottom: '1rem'
              }} 
            />
            <p style={{ fontSize: '1.1rem', lineHeight: '1.6', textAlign: 'justify' }}>
              <ul style={{ 
        listStyleType: 'disc',
        marginLeft: '0.5rem',
        marginBottom: '1rem',
        fontSize: '1.1rem',
        lineHeight: '1.6',
        textAlign: 'left',
      }}>
                <li>Enhance Learning Opportunities: Provide students with exposure to real-world applications of technology and hands-on experience</li>
                <li>Strengthen Industry Connections: Facilitate relationships between students and potential employers or mentors</li>
                <li>⁠Promote Innovation: Encourage creative problem-solving and innovation among participants</li>
              </ul>
            </p>
            <h4 style={{ color: '#36A9E1', marginBottom: '1rem' }}>Call For Speakers</h4>
            <p style={{ fontSize: '1.1rem', lineHeight: '1.6', textAlign: 'justify' }}>Do you want to share your insight and experience with us at SGIT 2024, We are seeking Dynamic, Diverse and inclusive speakers.
            </p>
            <h4 style={{ color: '#36A9E1', marginBottom: '1rem' }}>Our Partners and Sponsors</h4>
            <p style={{ fontSize: '1.1rem', lineHeight: '1.6' }}></p>

          </div>
        )}
      </Modal>

      <div className="container">
        <Contact />
      </div>
    </>
  );
};

export default EventsPage;