import React from 'react';
import { Headings } from '../components';
import { Team , CTA} from '../container';

import './About.css';


const AboutPage = () => {
  return (
    <>
      <div className="about-h" >
        <div className='container'>
          <Headings title="About Us" text="Mc Heritage consulting is a company that is sustainably changing the dynamics surrounding businesses by developing technology based solutions and developing bespoke strategies to help businesses scale whilst maintaining sustainable development."/>
        </div>
    </div>
    
    
    
    <div className="container">
      <CTA />
      <Team />
    </div></>
  )
}

export default AboutPage;